
// components/DocumentManager/CreateFolderModal.jsx
import React, { useState } from 'react';
import WideBlankModal from '../../../modals/WideBlankModal';

const CreateFolderModal = ({ isOpen, onClose, onCreate }) => {
    const [folderName, setFolderName] = useState('');

    const handleSubmit = () => {
        if (!folderName.trim()) return;
        onCreate(folderName);
        setFolderName('');
        onClose();
    };

    return (
        <WideBlankModal
            open={isOpen}
            setOpen={onClose}
            title="Create New Folder"
        >
            <div className="mt-4">
                <input
                    type="text"
                    placeholder="Folder name"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <div className="mt-4 flex justify-end space-x-3">
                    <button
                        className="btn-md btn-gray-outline"
                        onClick={onClose}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn-md btn-primary"
                        onClick={handleSubmit}
                        disabled={!folderName.trim()}
                    >
                        Create Folder
                    </button>
                </div>
            </div>
        </WideBlankModal>
    );
};

export default CreateFolderModal;