import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useParams } from 'react-router-dom';
import { FolderIcon, DocumentIcon } from '@heroicons/react/outline';

// Import components
import Toolbar from './Toolbar';
import Breadcrumbs from '../Breadcrumbs';
import UploadModal from './UploadModal';
import CreateFolderModal from './CreateFolderModal';
import { API_AUTH_TYPES, API_ROUTES, ApiRequest } from '../../../../api';
import FileList from './FileList';

const LoadingState = () => (
    <div className="space-y-6 p-4">
        {/* Folder skeletons */}
        {[1, 2].map((i) => (
            <div key={`folder-skeleton-${i}`} className="flex items-center space-x-4 animate-pulse">
                <div className="text-gray-200">
                    <FolderIcon className="h-10 w-10" />
                </div>
                <div className="h-5 bg-gray-200 rounded w-48" />
            </div>
        ))}

        {/* Document skeletons */}
        {[1, 2, 3].map((i) => (
            <div key={`doc-skeleton-${i}`} className="flex items-center space-x-4 animate-pulse">
                <div className="text-gray-200">
                    <DocumentIcon className="h-10 w-10" />
                </div>
                <div className="space-y-2 flex-1">
                    <div className="h-5 bg-gray-200 rounded w-64" />
                    <div className="h-3 bg-gray-200 rounded w-24" />
                </div>
            </div>
        ))}
    </div>
);

const DocumentManager = ({ authType = API_AUTH_TYPES.USER }) => {
    const { userId, household_id: householdId } = useParams();
    const isAdvisor = authType === API_AUTH_TYPES.ADVISOR;

    // Initialize API
    const documentsApi = new ApiRequest(API_ROUTES.DOCUMENTS, authType);
    const foldersApi = new ApiRequest(API_ROUTES.FOLDERS, authType);

    const queryClient = useQueryClient();
    const [currentFolderId, setCurrentFolderId] = useState(null);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    // Query keys
    const folderContentsKey = ['documents', 'folder-contents', currentFolderId, householdId];
    const folderKey = ['folder', currentFolderId, householdId];
    const subFoldersKey = ['folders', 'folder-contents', currentFolderId, householdId];

    // Fetch current folder and its contents
    const { data: currentFolder } = useQuery(
        folderKey,
        () => currentFolderId ? foldersApi.get({
            endpoint: 'get',
            params: isAdvisor ? [householdId, currentFolderId] : [currentFolderId]
        }) : null,
        {
            enabled: !!currentFolderId,
            select: (data) => ({
                ...data,
                documents: data?.documents || [],
                subfolders: data?.subfolders || []
            })
        }
    );

    // Fetch documents for current folder
    const { data: folderDocuments = [], isLoading: isLoadingDocuments } = useQuery(
        folderContentsKey,
        documentsApi.getFn({
            endpoint: 'index_household',
            params: isAdvisor ? [householdId] : [],
            queryParams: { folderId: currentFolderId }
        })
    );

    // Fetch subfolders
    const { data: subfolders = [], isLoading: isLoadingFolders } = useQuery(
        subFoldersKey,
        foldersApi.getFn({
            endpoint: 'index',
            params: isAdvisor ? [householdId] : [],
            queryParams: { parentFolderId: currentFolderId }
        })
    );

    // Search query
    const { data: searchResults = [] } = useQuery(
        ['documents', 'search', searchQuery, currentFolderId, householdId],
        documentsApi.getFn({
            endpoint: 'search_household',
            params: isAdvisor ? [householdId] : [],
            queryParams: {
                query: searchQuery,
                folderId: currentFolderId
            }
        }),
        {
            enabled: searchQuery.length > 0
        }
    );

    // Upload mutation with optimistic update
    const uploadMutation = useMutation({
        mutationFn: async (files) => {
            const uploads = files.map(file => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('folderId', currentFolderId || 'null');
                if (isAdvisor) {
                    formData.append('userId', userId);
                    formData.append('householdId', householdId);
                }
                return documentsApi.post({
                    endpoint: 'upload',
                    params: [],
                    body: formData
                });
            });
            return Promise.all(uploads);
        },
        onMutate: async (files) => {
            await queryClient.cancelQueries(folderContentsKey);
            const optimisticDocs = files.map((file, index) => ({
                id: `temp-${Date.now()}-${index}`,
                name: file.name,
                size: file.size,
                type: 'file',
                isOptimistic: true,
                uploadProgress: 0
            }));
            const previousDocs = queryClient.getQueryData(folderContentsKey);
            queryClient.setQueryData(folderContentsKey, old => [...(old || []), ...optimisticDocs]);
            return { previousDocs };
        },
        onError: (err, variables, context) => {
            queryClient.setQueryData(folderContentsKey, context.previousDocs);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(folderContentsKey);
            queryClient.invalidateQueries(folderKey);
        }
    });

    // Create folder mutation with optimistic update
    const createFolderMutation = useMutation({
        mutationFn: (name) => foldersApi.post({
            endpoint: 'create',
            params: [],
            body: {
                name,
                parentFolderId: currentFolderId,
                ...(isAdvisor && { householdId })
            }
        }),
        onMutate: async (name) => {
            await queryClient.cancelQueries(subFoldersKey);
            const optimisticFolder = {
                id: `temp-folder-${Date.now()}`,
                name,
                type: 'folder',
                isOptimistic: true
            };
            const previousFolders = queryClient.getQueryData(subFoldersKey);
            queryClient.setQueryData(subFoldersKey, old => [...(old || []), optimisticFolder]);
            return { previousFolders };
        },
        onError: (err, variables, context) => {
            queryClient.setQueryData(subFoldersKey, context.previousFolders);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(subFoldersKey);
            queryClient.invalidateQueries(folderKey);
        }
    });

    // Delete mutation with optimistic update
    const deleteMutation = useMutation({
        mutationFn: ({ id, type }) => {
            if (type === 'folder') {
                return foldersApi.patch({
                    endpoint: 'delete',
                    params: isAdvisor ? [id] : [id],
                    body: isAdvisor ? { householdId } : undefined
                });
            }
            return documentsApi.patch({
                endpoint: 'delete',
                params: [id],
                body: isAdvisor ? { householdId } : undefined
            });
        },
        onMutate: async ({ id, type }) => {
            const queryKey = type === 'folder' ? subFoldersKey : folderContentsKey;
            await queryClient.cancelQueries(queryKey);
            const previousData = queryClient.getQueryData(queryKey);
            queryClient.setQueryData(queryKey, old =>
                old?.filter(item => item.id !== id) || []
            );
            return { previousData, queryKey };
        },
        onError: (err, variables, context) => {
            queryClient.setQueryData(context.queryKey, context.previousData);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(folderContentsKey);
            queryClient.invalidateQueries(subFoldersKey);
            queryClient.invalidateQueries(folderKey);
        }
    });

    // Move mutation with optimistic update
    const moveMutation = useMutation({
        mutationFn: ({ itemId, itemType, targetFolderId }) => {
            const endpoint = itemType === 'file' ? 'move_documents' : 'move_folders';
            const api = itemType === 'file' ? documentsApi : foldersApi;
            return api.post({
                endpoint,
                params: [],
                body: {
                    [itemType === 'file' ? 'documentIds' : 'folderIds']: [itemId],
                    targetFolderId,
                    ...(isAdvisor && { householdId })
                }
            });
        },
        onMutate: async ({ itemId, itemType, targetFolderId }) => {
            await queryClient.cancelQueries(['documents', 'folder-contents']);
            await queryClient.cancelQueries(['folders', 'folder-contents']);
            await queryClient.cancelQueries(['folder']);

            const sourceKey = ['folders', 'folder-contents', currentFolderId, householdId];
            const targetKey = ['folders', 'folder-contents', targetFolderId, householdId];

            const previousSourceData = queryClient.getQueryData(sourceKey);
            const previousTargetData = queryClient.getQueryData(targetKey);
            const itemToMove = previousSourceData?.find(item => item.id === itemId);

            if (itemToMove) {
                queryClient.setQueryData(sourceKey, old =>
                    old?.filter(item => item.id !== itemId) || []
                );

                if (previousTargetData) {
                    queryClient.setQueryData(targetKey, old => [...(old || []), {
                        ...itemToMove,
                        parent_folder_id: targetFolderId
                    }]);
                }
            }

            return {
                previousSourceData,
                previousTargetData,
                sourceKey,
                targetKey
            };
        },
        onError: (err, variables, context) => {
            queryClient.setQueryData(context.sourceKey, context.previousSourceData);
            if (context.previousTargetData) {
                queryClient.setQueryData(context.targetKey, context.previousTargetData);
            }
        },
        onSuccess: () => {
            setTimeout(() => {
                queryClient.invalidateQueries(['documents', 'folder-contents']);
                queryClient.invalidateQueries(['folders', 'folder-contents']);
                queryClient.invalidateQueries(['folder']);
            }, 100);
        }
    });

    // Event handlers
    const handleSearch = (query) => setSearchQuery(query);
    const handleUpload = async (files) => await uploadMutation.mutateAsync(files);
    const handleCreateFolder = (name) => {
        createFolderMutation.mutate(name);
        setIsCreateFolderModalOpen(false);
    };
    const handleDelete = (id, type) => deleteMutation.mutate({ id, type });
    const handleMove = (item, targetFolderId) => {
        moveMutation.mutate({
            itemId: item.id,
            itemType: item.type,
            targetFolderId
        });
    };
    const handleNavigate = (folderId) => {
        setCurrentFolderId(folderId);
        setSearchQuery('');
    };

    // Display logic
    const displayFolders = subfolders;
    const displayDocuments = searchQuery ? searchResults : folderDocuments;
    const isLoading = isLoadingDocuments || isLoadingFolders ||
        (!!currentFolderId && !currentFolder);

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="flex flex-col h-full">
                <Toolbar
                    onUpload={() => setIsUploadModalOpen(true)}
                    onCreateFolder={() => setIsCreateFolderModalOpen(true)}
                    searchQuery={searchQuery}
                    onSearchChange={handleSearch}
                />

                <Breadcrumbs
                    breadcrumb={currentFolder?.breadcrumb}
                    onNavigate={handleNavigate}
                    onDrop={handleMove}
                />

                {isLoading ? (
                    <LoadingState />
                ) : (
                    <FileList
                        folders={displayFolders}
                        documents={displayDocuments}
                        onDeleteFolder={(id) => handleDelete(id, 'folder')}
                        onDeleteFile={(id) => handleDelete(id, 'file')}
                        onNavigate={handleNavigate}
                        onMove={handleMove}
                        isLoading={isLoading}
                        documentsApi={documentsApi}
                        authType={authType}
                        foldersApi={foldersApi}
                    />
                )}

                <UploadModal
                    isOpen={isUploadModalOpen}
                    onClose={() => setIsUploadModalOpen(false)}
                    onUpload={handleUpload}
                />

                <CreateFolderModal
                    isOpen={isCreateFolderModalOpen}
                    onClose={() => setIsCreateFolderModalOpen(false)}
                    onCreate={handleCreateFolder}
                />
            </div>
        </DndProvider>
    );
};

export default DocumentManager;