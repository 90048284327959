import React from 'react';
import {
    FiFile,
    FiImage,
    FiFileText,
    FiGrid,
    FiCode,
    FiFilm,
    FiMusic,
    FiArchive
} from 'react-icons/fi';
import { formatBytes } from '../../../../../helpers/formatters';

const CustomFilePreview = ({ file }) => {
    const getFileIcon = (mimeType, fileName) => {
        // Check file extension first
        const extension = fileName.split('.').pop()?.toLowerCase();

        // Common document types
        if (['pdf', 'doc', 'docx', 'txt', 'rtf'].includes(extension)) {
            return <FiFileText className="h-5 w-5 text-gray-500" />;
        }

        // Spreadsheets
        if (['xls', 'xlsx', 'csv'].includes(extension)) {
            return <FiGrid className="h-5 w-5 text-gray-500" />;
        }

        // Check mime types
        if (mimeType?.startsWith('image/')) {
            return <FiImage className="h-5 w-5 text-gray-500" />;
        }

        if (mimeType?.startsWith('video/')) {
            return <FiFilm className="h-5 w-5 text-gray-500" />;
        }

        if (mimeType?.startsWith('audio/')) {
            return <FiMusic className="h-5 w-5 text-gray-500" />;
        }

        if (mimeType?.startsWith('application/json') ||
            mimeType?.startsWith('text/javascript') ||
            mimeType?.startsWith('text/html')) {
            return <FiCode className="h-5 w-5 text-gray-500" />;
        }

        if (mimeType?.startsWith('application/zip') ||
            mimeType?.startsWith('application/x-rar-compressed')) {
            return <FiArchive className="h-5 w-5 text-gray-500" />;
        }

        // Default file icon
        return <FiFile className="h-5 w-5 text-gray-500" />;
    };

    return (
        <div className="relative bg-white p-4 rounded-lg border border-gray-200 shadow-lg max-w-[250px] hover:shadow-xl transition-all duration-200 transform hover:-translate-y-1">
            <div className="flex items-center gap-3">
                <div className="p-2 bg-gray-50 rounded-lg">
                    {getFileIcon(file.mime_type, file.original_name)}
                </div>
                <div className="flex flex-col min-w-0">
                    <span className="text-base font-medium text-gray-900 truncate max-w-[150px]">
                        {file.original_name}
                    </span>
                    <div className="flex items-center gap-2">
                        <span className="text-sm text-gray-500">
                            {formatBytes(file.file_size)}
                        </span>
                        {file.created_at && (
                            <span className="text-sm text-gray-400">
                                • {new Date(file.created_at).toLocaleDateString()}
                            </span>
                        )}
                    </div>
                </div>
            </div>

          
        </div>
    );
};

export default CustomFilePreview;