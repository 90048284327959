import React from "react";
import { useState, useCallback } from "react";

/**
 * Button Component for Smart Table
 * @param {Object} props
 * @param {Object} props.rowData - Data for the current table row
 * @param {string} props.colId - Column identifier
 * @param {Object} props.buttonFunctions - Object containing button click handler functions
 * @returns {React.Component} Button component with loading state
 */
const Button = ({ rowData, colId, buttonFunctions }) => {
  const [isLoading, setIsLoading] = useState(false);

  const executeScript =  () => {
    const body = rowData;
    const thisFunction = buttonFunctions[colId];
     thisFunction(body);
  }

  const handleClick = async () => {
    setIsLoading(true);
    try {
      // Force 3 second delay
       executeScript();
      await new Promise(resolve => setTimeout(resolve, 3000));

    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="smart-table-cell flex justify-center">
      <button
        name={`button_${colId}`}
        id={`button_${colId}`}
        onClick={handleClick}
        disabled={isLoading}
        className={`btn-primary px-3 py-0.5 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {isLoading ? (
          <span className="inline-flex items-center" data-testid="loading-indicator">
            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading...
          </span>
        ) : (
          'Click'
        )}
      </button>
    </div>
  );
};

export default Button;
