import React, { useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { FiUpload, FiAlertTriangle, FiX, FiCheckCircle } from 'react-icons/fi';
import WideBlankModal from '../../../modals/WideBlankModal';

const UploadModal = ({ isOpen, onClose, onUpload }) => {
    const fileInputRef = useRef(null);
    const [uploadStatus, setUploadStatus] = useState(''); // 'loading', 'success', 'error' or ''
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [NativeTypes.FILE],
        drop: async (item) => {
            const files = item.files;
            if (files?.length) {
                await handleUpload(files);
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    });

    const handleUpload = async (files) => {
        try {
            setUploadStatus('loading');
            setErrorMessage('');
            await onUpload(files);
            setUploadedFiles(prev => [...prev, ...Array.from(files).map(f => f.name)]);
            setUploadStatus('success');
        } catch (error) {
            console.error('Upload failed:', error);
            setUploadStatus('error');
            const responseData = error.response?.data;

            if (responseData) {
                switch (responseData.error) {
                    case 'VIRUS_SCAN_FAILED':
                        setErrorMessage('Security scan failed. Please try again later.');
                        break;
                    case 'FILE_TYPE_MISMATCH':
                        setErrorMessage('Invalid file type. Please check supported formats.');
                        break;
                    case 'FILE_STRUCTURE_INVALID':
                        setErrorMessage('Invalid file structure. Please ensure the file is not corrupted.');
                        break;
                    default:
                        setErrorMessage(responseData.message || 'Upload failed. Please try again.');
                }
            } else {
                setErrorMessage('Upload failed. Please try again.');
            }
        }
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    const handleFileSelect = async (event) => {
        const files = Array.from(event.target.files);
        if (files.length > 0) {
            await handleUpload(files);
        }
    };

    const handleClose = () => {
        setUploadStatus('');
        setUploadedFiles([]);
        setErrorMessage('');
        onClose();
    };

    const renderUploadContent = () => {
        if (uploadStatus === 'loading') {
            return (
                <div className="flex flex-col items-center gap-3">
                    <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-600" />
                    <p className="text-lg font-medium text-blue-800">Uploading files...</p>
                </div>
            );
        }

        if (uploadStatus === 'success') {
            return (
                <div className="flex flex-col items-center gap-4">
                    <div className="p-3 bg-green-100 rounded-lg">
                        <FiCheckCircle className="h-6 w-6 text-green-600" />
                    </div>
                    <div className="text-center">
                        <p className="text-lg font-semibold text-gray-900 mb-2">Upload Complete!</p>
                        <div className="text-sm text-gray-600 space-y-1">
                            {uploadedFiles.map((fileName, index) => (
                                <div key={index} className="flex items-center gap-2">
                                    <div className="h-2 w-2 rounded-full bg-green-400" />
                                    <span>{fileName}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }

        if (uploadStatus === 'error') {
            return (
                <div className="bg-red-50 border border-red-200 rounded-lg p-4">
                    <div className="flex items-start gap-3">
                        <div className="p-2 bg-red-100 rounded-lg">
                            <FiAlertTriangle className="h-5 w-5 text-red-600" />
                        </div>
                        <div>
                            <p className="text-red-800 font-medium mb-1">Upload Failed</p>
                            <p className="text-red-700 text-sm">{errorMessage}</p>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="text-center space-y-4">
                <div className="p-3 bg-blue-100 rounded-lg inline-flex">
                    <FiUpload className="h-6 w-6 text-blue-600" />
                </div>
                <div>
                    <p className="text-lg font-semibold text-gray-900 mb-2">
                        {isOver ? 'Drop files here' : 'Upload your files'}
                    </p>
                    <p className="text-sm text-gray-600">
                        Drag and drop or click to select
                    </p>
                </div>
                <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                    <div className="flex gap-3">
                        <FiUpload className="h-5 w-5 text-gray-400 flex-shrink-0 mt-0.5" />
                        <p className="text-sm text-gray-500">
                            Supported formats: PDF, DOC, DOCX, XLS, XLSX, CSV, JPG, PNG
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <WideBlankModal open={isOpen} setOpen={handleClose}>
            <div className="flex justify-between items-center border-b border-gray-200 pb-4">
                <h2 className="text-2xl font-bold text-gray-900">Upload Files</h2>
               
            </div>

            <div className="mt-6">
                <div
                    ref={drop}
                    onClick={handleClick}
                    className={`
                        bg-white rounded-lg border-2 border-dashed p-6
                        ${isOver ? 'border-blue-400 bg-blue-50' : 'border-gray-300'}
                        ${uploadStatus === 'success' ? 'border-green-400 bg-green-50' : ''}
                        ${uploadStatus === 'error' ? 'border-red-400' : ''}
                        transition-colors cursor-pointer
                    `}
                >
                    <input
                        ref={fileInputRef}
                        type="file"
                        multiple
                        onChange={handleFileSelect}
                        className="hidden"
                        accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png,.csv"
                    />
                    {renderUploadContent()}
                </div>
            </div>

            <div className="flex justify-end mt-6 gap-3">
                <button
                    onClick={handleClose}
                    className="btn-md btn-gray-outline"
                >
                    Close
                </button>
            </div>
        </WideBlankModal>
    );
};

export default UploadModal;