import React, { useState, useRef, useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FolderIcon, TrashIcon, FolderOpenIcon, PencilIcon, XIcon, CheckIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import { FiLoader } from 'react-icons/fi';
import { ExclamationIcon } from '@heroicons/react/outline';

const FolderItem = ({ folder, onDelete, onNavigate, onDrop, documentsApi, authType, foldersApi }) => {
    const { household_id: householdId } = useParams();
    const queryClient = useQueryClient();
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState(folder.name);
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef(null);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const renameMutation = useMutation({
        mutationFn: (newName) => foldersApi.patch({
            endpoint: "update",
            params: [folder.id],
            body: {
                name: newName,
                ...(authType === 'advisor' && { householdId })
            }
        }),
        onMutate: async (newName) => {
            // Cancel any outgoing refetches
            await queryClient.cancelQueries(['folders', 'folder-contents', folder.parent_id, householdId]);

            // Snapshot the previous value
            const previousFolders = queryClient.getQueryData(['folders', 'folder-contents', folder.parent_id, householdId]);

            // Optimistically update to the new value
            queryClient.setQueryData(['folders', 'folder-contents', folder.parent_id, householdId], old =>
                old?.map(f => f.id === folder.id ? { ...f, name: newName } : f)
            );

            return { previousFolders };
        },
        onError: (err, newName, context) => {
            queryClient.setQueryData(
                ['folders', 'folder-contents', folder.parent_id, householdId],
                context.previousFolders
            );
        },
        onSuccess: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries(['folders', 'folder-contents']);
            queryClient.invalidateQueries(['folder']);
            queryClient.invalidateQueries(['documents', 'search']);
        }
    });

    useEffect(() => {
        if (isEditing && inputRef.current) {
            inputRef.current.focus();
            inputRef.current.select();
        }
    }, [isEditing]);

    useEffect(() => {
        if (!isEditing) return;

        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                handleRename();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isEditing, editName]);

    const handleRename = async (e) => {
        e?.preventDefault();
        e?.stopPropagation();

        if (editName === folder.name) {
            setIsEditing(false);
            return;
        }

        if (!editName.trim()) {
            setEditName(folder.name);
            setIsEditing(false);
            return;
        }

        try {
            setIsLoading(true);
            await renameMutation.mutateAsync(editName.trim());
            setIsEditing(false);
        } catch (error) {
            console.error('Error renaming folder:', error);
            setEditName(folder.name);
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            setEditName(folder.name);
            setIsEditing(false);
        } else if (e.key === 'Enter') {
            handleRename(e);
        }
    };

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'FOLDER',
        item: {
            id: folder.id,
            type: 'folder',
            content: {
                props: {
                    folder,
                    onDelete,
                    onNavigate,
                }
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }));

    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: ['FILE', 'FOLDER'],
        drop: (item) => onDrop(item, folder.id),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }));

    // Combine drag and drop refs
    const dragDropRef = (el) => {
        drag(el);
        drop(el);
    };

    const isActive = isOver && canDrop;

    // Don't render the original component while dragging
    if (isDragging) {
        return <div ref={dragDropRef} className="opacity-0" />;
    }

    // Add this component inside FolderItem
    const DeleteConfirmDialog = ({ onConfirm, onCancel }) => (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6" onClick={e => e.stopPropagation()}>
                <div className="flex items-center gap-4">
                    <div className="flex-shrink-0 w-12 h-12 rounded-full bg-red-100 flex items-center justify-center">
                        <ExclamationIcon className="h-6 w-6 text-red-600" />
                    </div>
                    <div>
                        <h3 className="text-lg font-medium text-gray-900">Delete Folder</h3>
                        <p className="text-sm text-gray-500 mt-1">
                            Are you sure you want to delete "{folder.name}"? This will delete all files and folders inside it. This action cannot be undone.
                        </p>
                    </div>
                </div>
                <div className="mt-6 flex justify-end gap-3">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div
                ref={dragDropRef}
                onClick={!isEditing ? () => onNavigate(folder.id) : undefined}
                className={`
                    relative
                    p-2 rounded-lg
                    transition-all duration-200
                    group
                    w-full
                    ${isActive
                        ? 'bg-blue-100 border-2 border-blue-400 shadow-sm transform scale-105'
                        : canDrop
                            ? 'border-2 border-gray-200 hover:border-blue-300'
                            : 'border-2 border-transparent hover:bg-gray-50'
                    }
                `}
            >
                {/* Drop Zone Indicator */}
                {canDrop && (
                    <div className={`
                        absolute inset-0 rounded-lg
                        border-2 border-dashed
                        pointer-events-none
                        transition-opacity duration-200
                        ${isActive ? 'opacity-0' : 'opacity-100 border-gray-300'}
                    `} />
                )}

                <div className="flex items-start justify-between relative z-10">
                    <div className="flex items-center gap-3">
                        <div className={`
                            p-2 rounded-lg
                            ${isActive ? 'bg-blue-200' : 'bg-blue-50'}
                            transition-colors duration-200
                        `}>
                            {isLoading ? (
                                <FiLoader className="h-5 w-5 text-blue-500 animate-spin" />
                            ) : isActive ? (
                                <FolderOpenIcon className="h-5 w-5 text-blue-500 animate-pulse" />
                            ) : (
                                <FolderIcon className="h-5 w-5 text-blue-500" />
                            )}
                        </div>
                        {isEditing ? (
                            <div className="flex items-center gap-2" onClick={e => e.stopPropagation()}>
                                <input
                                    ref={inputRef}
                                    type="text"
                                    value={editName}
                                    onChange={(e) => setEditName(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    className="px-2 py-1 text-base sm:text-lg font-medium text-gray-900 border border-blue-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <button
                                    onClick={handleRename}
                                    className="p-1 hover:bg-green-100 rounded-lg transition-colors duration-200"
                                    disabled={isLoading}
                                >
                                    <CheckIcon className="h-5 w-5 text-green-500" />
                                </button>
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setEditName(folder.name);
                                        setIsEditing(false);
                                    }}
                                    className="p-1 hover:bg-red-100 rounded-lg transition-colors duration-200"
                                    disabled={isLoading}
                                >
                                    <XIcon className="h-5 w-5 text-red-500" />
                                </button>
                            </div>
                        ) : (
                            <span className="text-base sm:text-lg font-medium text-gray-900">
                                {folder.name}
                            </span>
                        )}
                    </div>

                    {!isEditing && (
                        <div className="hidden group-hover:flex items-center gap-2">
                            <button
                                className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsEditing(true);
                                }}
                                aria-label={`Rename ${folder.name} folder`}
                                disabled={isLoading}
                            >
                                <PencilIcon className="h-5 w-5 text-gray-500" />
                            </button>
                            <button
                                className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowDeleteConfirm(true);
                                }}
                                aria-label={`Delete ${folder.name} folder`}
                                disabled={isLoading}
                            >
                                <TrashIcon className="h-5 w-5 text-red-500" />
                            </button>
                        </div>
                    )}
                </div>

                {/* Optional metadata section */}
                {folder.itemCount > 0 && (
                    <div className="mt-2 text-sm text-gray-500 relative z-10">
                        {folder.itemCount} {folder.itemCount === 1 ? 'item' : 'items'}
                    </div>
                )}
            </div>

            {/* Add the delete confirmation dialog */}
            {showDeleteConfirm && (
                <DeleteConfirmDialog
                    onConfirm={() => {
                        onDelete(folder.id);
                        setShowDeleteConfirm(false);
                    }}
                    onCancel={() => setShowDeleteConfirm(false)}
                />
            )}
        </>
    );
};

export default FolderItem;