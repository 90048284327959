import React from 'react'
import DashboardPanel from '../../../../components/panels/DashboardPanel'
import DocumentManager from '../../../../components/fileManagement/components/DocumentManager'
import { API_AUTH_TYPES } from '../../../../api'

const HHDocuments = () => {
  return (
      <div className='flex flex-col w-full h-full'>
          <div className="max-w-6xl w-full mx-auto mt-4">
          <DashboardPanel>
              <DocumentManager authType={API_AUTH_TYPES.ADVISOR}/>
              </DashboardPanel>
          </div>
      </div>
  )
}

export default HHDocuments