import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';

const SpreadsheetPreview = ({ file, url }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const loadFile = async () => {
            try {
                setIsLoading(true);
                const response = await fetch(url);
                const blob = await response.blob();

                if (file.mime_type === 'text/csv') {
                    // Handle CSV files
                    const text = await blob.text();
                    Papa.parse(text, {
                        header: true,
                        dynamicTyping: true,
                        skipEmptyLines: true,
                        complete: (results) => {
                            if (!results.meta.fields || results.meta.fields.length === 0) {
                                setError('No data found in CSV file');
                                setIsLoading(false);
                                return;
                            }
                            setData({
                                headers: results.meta.fields,
                                rows: results.data.slice(0, 100) // Limit to first 100 rows for performance
                            });
                            setIsLoading(false);
                        },
                        error: (error) => {
                            setError('Error parsing CSV file');
                            setIsLoading(false);
                        }
                    });
                } else {
                    // Handle Excel files
                    const buffer = await blob.arrayBuffer();
                    const workbook = XLSX.read(buffer, {
                        type: 'array',
                        cellDates: true,
                        cellNF: true
                    });

                    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
                    const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

                    if (!jsonData || jsonData.length === 0) {
                        setError('No data found in Excel file');
                        setIsLoading(false);
                        return;
                    }

                    // Ensure we have valid headers
                    const headers = jsonData[0].map((header, index) =>
                        header || `Column ${index + 1}`
                    );

                    setData({
                        headers: headers,
                        rows: jsonData.slice(1, 101).map(row => {
                            // Ensure row has same length as headers
                            const paddedRow = [...row];
                            while (paddedRow.length < headers.length) {
                                paddedRow.push('');
                            }
                            return paddedRow;
                        })
                    });
                    setIsLoading(false);
                }
            } catch (err) {
                console.error('Error loading spreadsheet:', err);
                setError('Error loading file');
                setIsLoading(false);
            }
        };

        loadFile();
    }, [file, url]);

    if (isLoading) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="text-red-500">{error}</div>
            </div>
        );
    }

    if (!data || !data.headers || !data.rows) {
        return (
            <div className="flex items-center justify-center h-full">
                <div className="text-gray-500">No data available</div>
            </div>
        );
    }

    return (
        <div className="h-full overflow-auto">
            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50 sticky top-0">
                    <tr>
                        {data.headers.map((header, index) => (
                            <th
                                key={index}
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap"
                            >
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {data.rows.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {data.headers.map((_, colIndex) => (
                                <td
                                    key={`${rowIndex}-${colIndex}`}
                                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                                >
                                    {row[colIndex]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            {data.rows.length === 100 && (
                <div className="p-4 text-center text-sm text-gray-500">
                    Showing first 100 rows
                </div>
            )}
        </div>
    );
};

export default SpreadsheetPreview;