import React, { useState, useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { API_AUTH_TYPES } from '../../../../api';
import {
    FiFile, FiImage, FiFileText, FiGrid, FiCode,
    FiFilm, FiMusic, FiArchive, FiTrash2,
    FiLoader, FiEdit2, FiCheck, FiX
} from 'react-icons/fi';
import { formatBytes } from '../../../../helpers/formatters';
import FilePreview from './FilePreview';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useQueryClient, useMutation } from 'react-query';
import { ExclamationIcon } from '@heroicons/react/outline';

const FileItem = ({ file, onDelete, documentsApi, authType }) => {
    const { household_id: householdId } = useParams();
    const queryClient = useQueryClient();
    const inputRef = useRef(null);
    const [showPreview, setShowPreview] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [signedUrl, setSignedUrl] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [newName, setNewName] = useState(file.name || file.original_name);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    const isAdvisor = authType === API_AUTH_TYPES.ADVISOR;

    const renameMutation = useMutation({
        mutationFn: (newName) => documentsApi.patch({
            endpoint: "update",
            params: [file.id],
            body: {
                name: newName,
                ...(isAdvisor && { householdId: file.household_id })
            }
        }),
        onMutate: async (newName) => {
            await queryClient.cancelQueries(['documents']);
            const previousFiles = queryClient.getQueryData(['documents', 'folder-contents', file.folder_id, householdId]);
            queryClient.setQueryData(['documents', 'folder-contents', file.folder_id, householdId], old =>
                old?.map(doc => doc.id === file.id ? { ...doc, name: newName } : doc)
            );
            return { previousFiles };
        },
        onError: (err, newName, context) => {
            queryClient.setQueryData(
                ['documents', 'folder-contents', file.folder_id, householdId],
                context.previousFiles
            );
        },
        onSuccess: () => {
            queryClient.invalidateQueries(['documents', 'folder-contents']);
            queryClient.invalidateQueries(['documents', 'search']);
        }
    });

    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'FILE',
        item: {
            id: file.id,
            type: 'file',
            content: {
                props: {
                    file,
                    onDelete,
                }
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }));

    useEffect(() => {
        if (!isEditing) return;

        const handleClickOutside = (event) => {
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                handleRename();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [isEditing, newName]);

    if (isDragging) {
        return <div ref={drag} className="opacity-0" />;
    }

    const getFileIcon = (mimeType, fileName) => {
        const extension = fileName.split('.').pop()?.toLowerCase();

        if (['pdf', 'doc', 'docx', 'txt', 'rtf'].includes(extension)) {
            return <FiFileText className="h-5 w-5 text-gray-500" />;
        }
        if (['xls', 'xlsx', 'csv'].includes(extension)) {
            return <FiGrid className="h-5 w-5 text-gray-500" />;
        }
        if (mimeType?.startsWith('image/')) {
            return <FiImage className="h-5 w-5 text-gray-500" />;
        }
        if (mimeType?.startsWith('video/')) {
            return <FiFilm className="h-5 w-5 text-gray-500" />;
        }
        if (mimeType?.startsWith('audio/')) {
            return <FiMusic className="h-5 w-5 text-gray-500" />;
        }
        if (mimeType?.startsWith('application/json') ||
            mimeType?.startsWith('text/javascript') ||
            mimeType?.startsWith('text/html')) {
            return <FiCode className="h-5 w-5 text-gray-500" />;
        }
        if (mimeType?.startsWith('application/zip') ||
            mimeType?.startsWith('application/x-rar-compressed')) {
            return <FiArchive className="h-5 w-5 text-gray-500" />;
        }
        return <FiFile className="h-5 w-5 text-gray-500" />;
    };

    const fetchSignedUrl = async (documentId) => {
        if (!documentId) return null;

        const params = isAdvisor ?
            [householdId, documentId] :
            [documentId];

        const response = await documentsApi.get({
            endpoint: "get",
            params
        });
        return response.signed_url;
    };

    const handleFileClick = async (e) => {
        if (showPreview) return;
        try {
            setIsLoading(true);
            const url = await fetchSignedUrl(file.id);
            if (url) {
                setSignedUrl(url);
                setShowPreview(true);
            }
        } catch (error) {
            console.error('Error fetching signed URL:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async () => {
        try {
            setIsLoading(true);
            const url = await fetchSignedUrl(file.id);
            if (url) {
                const response = await fetch(url);
                const blob = await response.blob();
                const blobUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = blobUrl;
                link.download = file.original_name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(blobUrl);
            }
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleRename = async (e) => {
        e?.stopPropagation?.();
        if (newName === (file.name || file.original_name)) {
            setIsEditing(false);
            return;
        }

        try {
            setIsLoading(true);
            await renameMutation.mutateAsync(newName);
            setIsEditing(false);
        } catch (error) {
            console.error('Error renaming file:', error);
            setNewName(file.name || file.original_name);
        } finally {
            setIsLoading(false);
        }
    };

    const DeleteConfirmDialog = ({ onConfirm, onCancel }) => (
        <div className="fixed inset-0 bg-black bg-opacity-30 z-50 flex items-center justify-center p-4">
            <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6" onClick={e => e.stopPropagation()}>
                <div className="flex items-center gap-4">
                    <div className="flex-shrink-0 w-12 h-12 rounded-full bg-red-100 flex items-center justify-center">
                        <ExclamationIcon className="h-6 w-6 text-red-600" />
                    </div>
                    <div>
                        <h3 className="text-lg font-medium text-gray-900">Delete File</h3>
                        <p className="text-sm text-gray-500 mt-1">
                            Are you sure you want to delete "{file.name || file.original_name}"? This action cannot be undone.
                        </p>
                    </div>
                </div>
                <div className="mt-6 flex justify-end gap-3">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </div>
    );

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        setShowDeleteConfirm(true);
    };

    return (
        <div
            ref={drag}
            onClick={!isEditing ? handleFileClick : undefined}
            className="grid grid-cols-12 items-center px-4 py-2 hover:bg-gray-50 transition-colors duration-200 cursor-pointer group"
        >
           

            {/* File Name Column */}
            <div className="col-span-7 flex items-center gap-3">
                <div className="p-2 bg-gray-50 rounded-lg">
                    {isLoading ? (
                        <FiLoader className="h-5 w-5 text-gray-500 animate-spin" />
                    ) : (
                        getFileIcon(file.mime_type, file.original_name)
                    )}
                </div>
                <div className="flex flex-col min-w-0">
                    {isEditing ? (
                        <input
                            ref={inputRef}
                            type="text"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            onClick={(e) => e.stopPropagation()}
                            className="text-base font-medium text-gray-900 border border-gray-300 rounded px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            autoFocus
                        />
                    ) : (
                        <span className="text-base font-medium text-gray-900 truncate max-w-xs">
                            {file.name || file.original_name}
                        </span>
                    )}
                </div>
            </div>

            {/* Upload Date Column */}
            <div className="col-span-3 text-sm text-gray-500">
                {new Date(file.created_at).toLocaleDateString()}
            </div>

            {/* Size Column */}
            <div className="col-span-1 text-sm text-gray-500">
                {formatBytes(file.file_size)}
            </div>

            {/* Actions Column */}
            <div className="col-span-1 hidden group-hover:flex items-center justify-end gap-2">
                {isEditing ? (
                    <>
                        <button
                            className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                            onClick={handleRename}
                            disabled={isLoading}
                            aria-label="Save name"
                        >
                            <FiCheck className="h-5 w-5 text-green-500" />
                        </button>
                        <button
                            className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditing(false);
                            }}
                            disabled={isLoading}
                            aria-label="Cancel rename"
                        >
                            <FiX className="h-5 w-5 text-gray-500" />
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditing(true);
                            }}
                            disabled={isLoading}
                            aria-label={`Rename ${file.original_name}`}
                        >
                            <FiEdit2 className="h-5 w-5 text-gray-500" />
                        </button>
                        <button
                            className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                            onClick={handleDeleteClick}
                            aria-label={`Delete ${file.original_name}`}
                            disabled={isLoading}
                        >
                            <FiTrash2 className="h-5 w-5 text-red-500" />
                        </button>
                    </>
                )}
            </div>

            {/* File Preview Modal */}
            {showPreview && signedUrl && (
                <FilePreview
                    file={{ ...file, url: signedUrl }}
                    onClose={(e) => {
                        e?.stopPropagation?.();
                        setShowPreview(false);
                        setSignedUrl(null);
                    }}
                    onDownload={handleDownload}
                />
            )}

            {showDeleteConfirm && (
                <DeleteConfirmDialog
                    onConfirm={() => {
                        onDelete(file.id);
                        setShowDeleteConfirm(false);
                    }}
                    onCancel={() => setShowDeleteConfirm(false)}
                />
            )}
        </div>
    );
};

export default FileItem;