/* eslint-disable no-use-before-define */
export const API_ROUTES = {
  ACTION_CENTER: "action_center",
  ADVISOR_DASHBOARD: "advisor_dashboard",
  ADVISOR_RESOURCES: "advisor_resources",
  AGENTS: "agents",
  ANALYTICS: "analytics",
  ANALYTICS_VIEWS: "analytics_views",
  ASSET_ALLOCATIONS: "asset_allocations",
  ASSET_CLASS_KEYWORDS: "asset_class_keywords",
  ASSET_CLASSES: "asset_classes",
  BEEHIIV: 'beehiiv',
  BLOG: "blog",
  BUNMAIL: "bunmail",
  CALENDLY: "external/calendly",
  CALENDLY_MEETING_TYPES: "calendly_meeting_types",
  CASHFLOW: 'cashflow',
  CLIENT_EMAILS: "client_emails",
  CLIENT_MEETINGS: "client_meetings",
  CLIENT_TASKS: "client_tasks",
  CHAPTER_TEMPLATES: "chapter_templates",
  CLOUDINARY: "external/cloudinary",
  COMPANY_SETTINGS: "company_settings",
  CUSTOM_FORMS: "custom_forms",
  DOCUMENTS: "documents",
  EMAIL_JS: "external/emailjs",
  EMAIL_THREADS: "email_threads",
  FEE_SAVINGS_ITEMS: "fee_savings_items",
  FINANCIAL_STORY_CHAPTERS: "financial_story_chapters",
  FINANCIAL_STORY_TEMPLATES: "financial_story_templates",
  FOLDERS: "folders",
  GMAIL: "external/gmail",
  HISTORICAL_FEE_SAVINGS: "historical_fee_savings",
  HISTORICAL_INVESTMENT_ACCOUNT_AUA: "historical_user_account_aua",
  HISTORICAL_USER_AUA: "historical_user_aua",
  HOUSEHOLDS: "households",
  HOUSEHOLD_GOALS: "household_goals",
  HOUSEHOLD_NOTES: "household_notes",
  HOUSEHOLD_SEARCH: 'household_search',
  INVESTMENET_ACCOUNTS: "useraccounts",
  INVESTMENT_CONTROLS_LOGGER: "investment_controls_logger",
  YOUVEST_SUBMISSIONS: "investment_tool_submission",
  LEARNING_LAB: "learning_lab",
  LEROY: 'apps/leroy',
  MANAGER_DASHBOARD: "manager_dashboard",
  MEETING_NOTE_PROMPTS: "meeting_note_prompts",
  MIXPANEL: "external/mixpanel",
  NOTE_GENERATION: 'note-generation',
  NOTIFICATIONS: "notifications",
  ONBOARDING: "onboarding",
  ONGOING_TARGETS: "ongoing_targets",
  OPENAI: "external/openai",
  QUILL_TEXT_EDITOR: "quill_text_editor",
  PANDA_DOC: "external/panda_doc",
  PARABOT: "parabot",
  PARABOT_WORKFLOWS: "parabot_workflows",
  PLAID_ACCESS_TOKENS: "plaid_access_tokens",
  PLAID_ACCOUNT_TYPES_KEY: "plaid_account_types_key",
  POINTS_PILOT: "points_pilot",
  PORTFOLIOS: "portfolios",
  PROSPECTS: "prospects",
  PROSPECT_ASSIGNMENT: "prospect_assignment",
  PRE_APPROVED_USERS: "pre_approved_users",
  RECOMMENDED_CREDIT_CARDS: "recommended_credit_cards",
  REFRESH_TOKENS: "refresh_tokens",
  RISK_TOLERANCE: "risk_tolerance",
  SMART_TABLE: 'apps/smart-table',
  SMART_TABLE_VIEWS: 'smart_table_views',
  STATS: "stats",
  STRIPE: "external/stripe",
  TIMERS: "timers",
  USER_ACTIVITY: "user_activity",
  USERS: "users",
  YOUVEST: 'youvest',

};

export const API_AUTH_TYPES = {
  USER: "user",
  ADVISOR: "advisor",
  ADMIN: "admin",
  MANAGER: 'manager',
  LEARNING_LAB: "learning_lab",
  BLOG: "blog",
  NONE: 'none'
};

