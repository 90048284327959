import React from 'react';
import { FolderIcon } from '@heroicons/react/outline';

const CustomFolderPreview = ({ folder }) => {
    return (
        <div className="bg-white p-4 rounded-lg border border-blue-400 shadow-lg max-w-[250px] rotate-[2deg]">
            <div className="flex items-center gap-3">
                <div className="p-2 bg-blue-50 rounded-lg">
                    <FolderIcon className="h-5 w-5 text-blue-500" />
                </div>
                <span className="text-base font-medium text-gray-900 truncate">
                    {folder.name}
                </span>
            </div>
        </div>
    );
};

export default CustomFolderPreview;