import React from 'react'
import { useDragLayer } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import Task from '../../routes/AdvisorPortal/household/Financial-Story/components/task-components/Task'
import ChapterItem from '../../routes/AdvisorPortal/household/Financial-Story/components/TableOfContents/ChapterItem'
import ChapterGoal from '../../routes/AdvisorPortal/household/Financial-Story/components/chapter-components/ChapterGoal'
import CustomFolderPreview from '../fileManagement/components/DocumentManager/customDragPreviews/CusomFolderPreview'
import CustomFilePreview from '../fileManagement/components/DocumentManager/customDragPreviews/CustomFilePreview'

const layerStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
}

const ITEM_OFFSETS = {
    task: {
        x: 680,
        y: 20,
    },
    chapter: {
        x: 215,
        y: 33,
    },
    goal: {
        x: 700,
        y: 25,
    },
    folder: {
        x: 100,
        y: 33,
    },
    file: {
        x: 100,
        y: 33,
    },
};

const getYOffset = (itemType) => ITEM_OFFSETS[itemType]?.y ?? 0;

const getXOffset = (itemType) => ITEM_OFFSETS[itemType]?.x ?? 0;
const getItemStyles = (itemType, currentOffset) => {
    const initialOffsetX = getXOffset(itemType)
    const initialOffsetY = getYOffset(itemType)

    if (!currentOffset) {
        return {
            display: 'none',
        }
    }
    const x = currentOffset.x - initialOffsetX
    const y = currentOffset.y - initialOffsetY

    const transform = `translate(${x}px, ${y}px)`
    return {
        transform,
        WebkitTransform: transform,
    }
}

const CustomDragLayer = () => {
    const { isDragging, item, currentOffset } = useDragLayer(
        (monitor) => ({
            item: monitor.getItem(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        })
    )

    const renderItem = (item) => {
        // Handle native file drops
        if (item.type === NativeTypes.FILE || !item.content) {
            return null;
        }

        const props = item.content.props
        if (item.type === 'task') {
            return (
                <div className="max-w-screen-md rotate-[2deg] transition duration-300 ease-in-out">
                    <Task task={props.task}
                        tasksListQueryKey={props.tasksListQueryKey}
                        chaptersListQueryKey={props.chaptersListQueryKey}
                        isMeetingMode={props.isMeetingMode} />
                </div>
            )
        }
        if (item.type === 'chapter') {
            return (
                <div className="max-w-[250px] rotate-[2deg] transition duration-300 ease-in-out">
                    <ChapterItem
                        selectedChapterId={props.selectedChapterId}
                        setSelectedChapterId={props.setSelectedChapterId}
                        chapter={props.chapter}
                    />
                </div>
            )
        }
        if (item.type === 'goal') {
            return (
                <div className="max-w-screen-md rotate-[2deg] transition duration-300 ease-in-out">
                    <ChapterGoal goal={props.goal} queryKeys={props.queryKeys} />
                </div>
            )
        }
        if (item.type === 'folder') {
            return (
                <div className="max-w-[250px] rotate-[2deg] transition duration-300 ease-in-out">
                    <CustomFolderPreview folder={props.folder} />
                </div>
            );
        }
        if (item.type === 'file') {
            return (
                <div className="max-w-[250px] rotate-[2deg] transition duration-300 ease-in-out">
                    <CustomFilePreview file={props.file} />
                </div>
            );
        }
    }

    if (!isDragging) {
        return null
    }

    return (
        <div style={layerStyles}>
            <div style={getItemStyles(item.type, currentOffset)}>
                {renderItem(item)}
            </div>
        </div>
    )
}

export default CustomDragLayer
