import React, { useMemo, useState } from "react";
import MarkdownNotes from "../../../../../../components/content/MarkdownNotes";
import RichTextDisplay from "../../../../../../components/textEditor/RichTextDisplay";
import { classNames } from "../../../../../../helpers/classNames";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";

const DisplayTaskRichText = ({ task, isClientPortalTask, query, isTruncated = false }) => {
  const [isExpanded, setIsExpanded] = useState(!isTruncated);
  const taskClassName = useMemo(() => isClientPortalTask ? "client-portal-task " : "markdown-notes", [])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={classNames(
      (task.status === 'completed' && isClientPortalTask) ? 'line-through text-gray-400' : 'text-gray-800',
      "text-left w-full",
      task.status !== 'highlighted' && 'text-gray-400'
    )}>
      {task?.content && (
        <div>
          {isTruncated ? (
            !isExpanded ? (
              <div
                onClick={toggleExpand}
                className="flex items-start justify-between gap-2 cursor-pointer group"
              >
                <p className="truncate max-w-prose flex-1">
                  {task.content?.ops[0].insert.trim().split(' ').slice(0, 100).join(" ")}
                </p>
                <div className="inline-flex items-center px-2 py-1 text-sm text-gray-600 group-hover:text-gray-800">
                  <ChevronDownIcon className="h-5 w-5" />
                </div>
              </div>
            ) : (
              <div
                onClick={toggleExpand}
                className="cursor-pointer group"
              >
                <RichTextDisplay
                  content={task.content}
                  thisClassName={taskClassName}
                  query={query}
                />
                <div className="flex justify-between items-center mt-2">
                  <div></div>
                  <div className="inline-flex items-center px-2 py-1 text-sm text-gray-600 group-hover:text-gray-800">
                    <ChevronUpIcon className="h-5 w-5" />
                  </div>
                </div>
              </div>
            )
          ) : (
            <RichTextDisplay
              content={task.content}
              thisClassName={taskClassName}
              query={query}
            />
          )}
        </div>
      )}

      {task?.description?.delta && (
        <div>
          <RichTextDisplay
            content={{ ops: task.description.delta }}
            thisClassName={taskClassName}
            query={query}
          />
        </div>
      )}

      {task?.description?.blocks && (
        <>
          <MarkdownNotes noteRaw={task.description} />
        </>
      )}
    </div>
  );
};

export default DisplayTaskRichText;