
import {
  internalGetReq,
  internalPostReq,
  internalPatchReq,
  internalDeleteReq,
} from "./requestFunctions";

class ApiRequest {
  constructor(route, authType) {
    this.internalGetReq = internalGetReq;
    this.internalPostReq = internalPostReq;
    this.internalPatchReq = internalPatchReq;
    this.internalDeleteReq = internalDeleteReq
    this.authType = authType;
    this.route = route;
  }
  async post(options = { endpoint: "", body: {}, params: [] }) {
    return await this.internalPostReq(
      this.authType,
      this.route,
      options.endpoint,
      options.body,
      options.params
    );
  }

  postFn(options = { endpoint: "",body:{}, params: [] }) {
    return async () =>
      await this.internalPostReq(
        this.authType,
        this.route,
        options.endpoint,
        options.body,
        options.params
      );
  }

  async get(options = { endpoint: "", params: [], queryParams: {}, fullRequest: false }) {
    return await this.internalGetReq(
      this.authType,
      this.route,
      options.endpoint,
      options.params,
      options.version || 'v1',
      options.fullRequest || false,
      options.queryParams || {}
    );
  }

  getFn(options = { endpoint: "", params: [], queryParams: {} }) {
    return async () =>
      await this.internalGetReq(
        this.authType,
        this.route,
        options.endpoint,
        options.params,
        options.version || 'v1',
        false,
        options.queryParams || {}
      );
  }
  async findAll() {
    const endpoint = "find_all";
    return await this.internalGetReq(this.authType, this.route, endpoint);
  }

  async findAllMy() {
    //uses the user_id from accesstoken as a search parameter

    const endpoint = "find_all_my";
    return await this.internalGetReq(this.authType, this.route, endpoint);
  }

  async findManyWhere(search, params = []) {
    const endpoint = `find_many_where_${search}`;
    return await this.internalGetReq(
      this.authType,
      this.route,
      endpoint,
      params
    );
  }

  async findUnique(params = []) {
    const endpoint = "find_unique";
    return async () =>
      await this.internalGetReq(this.authType, this.route, endpoint, params);
  }

  async create(body, params = []) {
    const endpoint = "create";
    return await this.internalPostReq(
      this.authType,
      this.route,
      endpoint,
      body,
      params
    );
  }

  async update(body, params = []) {
    const endpoint = "update";
    return await this.internalPatchReq(
      this.authType,
      this.route,
      endpoint,
      body,
      params
    );
  }

  async patch(options = { endpoint: "", body: {}, params: [] }) {
    return await this.internalPatchReq(
      this.authType,
      this.route,
      options.endpoint,
      options.body,
      options.params
    );
  }

  async delete(options = { endpoint: "", params: [] }) {
    return await this.internalDeleteReq(
      this.authType,
      this.route,
      options.endpoint,
      options.params
    );
  }
  async deleteUnique(params = []) {
    const endpoint = "delete_unique";

    return await this.internalGetReq(
      this.authType,
      this.route,
      endpoint,
      params
    );
  }
}

export default ApiRequest;
