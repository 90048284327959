// components/DocumentManager/FilePreview.jsx
import React, { useState } from 'react';
import {
    FiX,
    FiDownload,
    FiMaximize2,
    FiMinimize2,
    FiFile,
    FiImage,
    FiFileText,
    FiGrid,
    FiCode,
    FiFilm,
    FiMusic,
    FiArchive
} from 'react-icons/fi';
import { formatBytes } from '../../../../../helpers/formatters';
import SpreadsheetPreview from './SpreadsheetPreview';

const FilePreview = ({ file, onClose, onDownload }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const getFileIcon = (mimeType, fileName) => {
        const extension = fileName.split('.').pop()?.toLowerCase();

        if (['pdf', 'doc', 'docx', 'txt', 'rtf'].includes(extension)) {
            return <FiFileText className="h-8 w-8 text-gray-500" />;
        }
        if (['xls', 'xlsx', 'csv'].includes(extension)) {
            return <FiGrid className="h-8 w-8 text-gray-500" />;
        }
        if (mimeType?.startsWith('image/')) {
            return <FiImage className="h-8 w-8 text-gray-500" />;
        }
        if (mimeType?.startsWith('video/')) {
            return <FiFilm className="h-8 w-8 text-gray-500" />;
        }
        if (mimeType?.startsWith('audio/')) {
            return <FiMusic className="h-8 w-8 text-gray-500" />;
        }
        if (mimeType?.startsWith('application/json') ||
            mimeType?.startsWith('text/javascript') ||
            mimeType?.startsWith('text/html')) {
            return <FiCode className="h-8 w-8 text-gray-500" />;
        }
        if (mimeType?.startsWith('application/zip') ||
            mimeType?.startsWith('application/x-rar-compressed')) {
            return <FiArchive className="h-8 w-8 text-gray-500" />;
        }
        return <FiFile className="h-8 w-8 text-gray-500" />;
    };
    console.log('File Preview:', file);

    const renderPreviewContent = () => {
        if (file.mime_type?.startsWith('image/')) {
            return (
                <div className="flex items-center justify-center h-full">
                    <img
                        src={file.url}
                        alt={file.original_name}
                        className="max-w-full max-h-full object-contain"
                    />
                </div>
            );
        }

        if (file.mime_type?.startsWith('video/')) {
            return (
                <div className="flex items-center justify-center h-full">
                    <video
                        controls
                        className="max-w-full max-h-full"
                    >
                        <source src={file.url} type={file.mime_type} />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        }

        if (file.mime_type?.startsWith('audio/')) {
            return (
                <div className="flex items-center justify-center h-full">
                    <audio controls>
                        <source src={file.url} type={file.mime_type} />
                        Your browser does not support the audio element.
                    </audio>
                </div>
            );
        }

        if (file.mime_type === 'application/pdf') {
            return (
                <div className={`w-full h-full ${isFullscreen ? 'max-h-screen' : ''}`}>
                    <iframe
                        src={file.url}
                        className="w-full h-full"
                        title={file.original_name}
                        style={{
                            height: isFullscreen ? '100vh' : '70vh',
                            border: 'none'
                        }}
                    />
                </div>
            );
        }
        if (file.mime_type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
            file.mime_type === 'application/vnd.ms-excel' ||
            file.mime_type === 'text/csv') {
            return <SpreadsheetPreview file={file} url={file.url} />;
        }

        // For non-previewable file types, show a preview card
        return (
            <div className="flex flex-col items-center justify-center h-full">
                <div className="bg-gray-50 p-8 rounded-lg text-center">
                    {getFileIcon(file.mime_type, file.original_name)}
                    <h3 className="mt-4 text-lg font-medium text-gray-900">
                        {file.original_name}
                    </h3>
                    <p className="mt-2 text-sm text-gray-500">
                        {formatBytes(file.file_size)}
                    </p>
                    <p className="mt-4 text-sm text-gray-600">
                        Preview not available for this file type
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center"
            onClick={(e) => {
                e.stopPropagation();
                onClose();
            }}>
            <div
                onClick={(e) => e.stopPropagation()}
                className={`bg-white rounded-lg shadow-xl overflow-hidden ${isFullscreen ? 'fixed inset-0' : 'relative w-11/12 h-5/6 max-w-4xl'
                    }`}
            >
                {/* Header */}
                <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200">
                    <div className="flex items-center gap-3">
                        {getFileIcon(file.mime_type, file.original_name)}
                        <div className="flex flex-col">
                            <h2 className="text-lg font-medium text-gray-900">
                                {file.name || file.original_name}
                            </h2>
                            <p className="text-sm text-gray-500">
                                {formatBytes(file.file_size)}
                            </p>
                        </div>
                    </div>
                    <div className="flex items-center gap-2">
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onDownload?.();
                            }}
                            className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                            aria-label="Download file"
                        >
                            <FiDownload className="h-5 w-5 text-gray-500" />
                        </button>
                        <button
                            onClick={() => setIsFullscreen(!isFullscreen)}
                            className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                            aria-label={isFullscreen ? "Exit fullscreen" : "Enter fullscreen"}
                        >
                            {isFullscreen ? (
                                <FiMinimize2 className="h-5 w-5 text-gray-500" />
                            ) : (
                                <FiMaximize2 className="h-5 w-5 text-gray-500" />
                            )}
                        </button>
                        <button
                            onClick={onClose}
                            className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
                            aria-label="Close preview"
                        >
                            <FiX className="h-5 w-5 text-gray-500" />
                        </button>
                    </div>
                </div>

                {/* Preview Content */}
                <div className="h-[calc(100%-4rem)] overflow-auto">
                    {renderPreviewContent()}
                </div>
            </div>
        </div>
    );
};

export default FilePreview;