import SmartTable from "../../../components/smart-table/SmartTable";

/**
 * HouseholdsSmartTable component that renders a smart table for household data.
 * Wraps the generic SmartTable component with household-specific configuration.
 * 
 * @component
 * @returns {JSX.Element} A configured SmartTable component for displaying household data
 */
const HouseholdsSmartTable = () => {

  return (
    <SmartTable
      tableId={"households"}
      urlBase={"households"}
    />
  );
};

export default HouseholdsSmartTable;
