import { FolderOpenIcon, HomeIcon } from "@heroicons/react/solid";
import { useDrop } from "react-dnd";

const { ChevronRightIcon } = require("@heroicons/react/outline");

const BreadcrumbItem = ({ item, isLast, onNavigate, onDrop, isRootFolder }) => {
    const [{ isOver, canDrop }, drop] = useDrop(() => ({
        accept: ['FILE', 'FOLDER'],
        canDrop: () => {
            // Allow drops on home always
            if (item.isHome) return true;
            // Allow drops on last item only if we're in root folder
            if (isLast) return isRootFolder;
            // Allow drops on all other breadcrumb items
            return true;
        },
        drop: (draggedItem) => onDrop(draggedItem, item.isHome ? null : item.id),
        collect: monitor => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
    }));

    const isActive = isOver && canDrop;

    return (
        <li className="flex items-center">
            <div
                ref={drop}
                className={`
                    flex items-center gap-2
                    px-3 py-2 rounded-md
                    transition-all duration-200
                    relative
                    ${isActive
                        ? 'bg-blue-100 border-2 border-blue-400 shadow-sm transform scale-105'
                        : canDrop
                            ? 'border-2 border-gray-200 hover:border-blue-300'
                            : 'border-2 border-transparent'
                    }
                    ${canDrop ? 'cursor-pointer' : 'cursor-default'}
                `}
            >
                {/* Drop Zone Indicator */}
                {canDrop && (
                    <div className={`
                        absolute inset-0 rounded-md
                        border-2 border-dashed
                        pointer-events-none
                        transition-opacity duration-200
                        ${isActive ? 'opacity-0' : 'opacity-100 border-gray-300'}
                    `} />
                )}

                {/* Content */}
                <div className="flex items-center gap-2">
                    {item.isHome ? (
                        <>
                            <div
                                onClick={() => onNavigate(null)}
                                className="text-gray-400 hover:text-gray-500 flex items-center"
                            >
                                <HomeIcon className="h-5 w-5" aria-hidden="true" />
                                <span className="sr-only">Home</span>
                            </div>
                        </>
                    ) : (
                        <>
                            <button
                                onClick={() => onNavigate(item.id)}
                                className={`
                                    text-sm font-medium flex items-center gap-2
                                    ${isLast ? 'text-gray-800' : 'text-gray-500 hover:text-gray-700'}
                                    transition-colors duration-200
                                `}
                            >
                                {item.name}
                            </button>
                        </>
                    )}

                    {/* Drop Indicator Icon */}
                    {isActive && (
                        <FolderOpenIcon className="h-4 w-4 text-blue-500 animate-pulse" />
                    )}
                </div>
            </div>

            {!isLast && (
                <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400 mx-2"
                    aria-hidden="true"
                />
            )}
        </li>
    );
};

export default BreadcrumbItem;