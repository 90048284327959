import React from 'react';
import { FiPlus, FiFolderPlus, FiSearch } from 'react-icons/fi';

const Toolbar = ({
    onUpload,
    onCreateFolder,
    searchQuery,
    onSearchChange
}) => {
    return (
        <div className="flex justify-between items-center pb-4 border-b border-gray-200">
            <div className="flex space-x-2">
                <button
                    className="btn-md btn-primary flex items-center gap-2"
                    onClick={onUpload}
                >
                    <FiPlus className="w-4 h-4" />
                    Upload Files
                </button>
                <button
                    className="btn-md btn-gray-outline flex items-center gap-2"
                    onClick={onCreateFolder}
                >
                    <FiFolderPlus className="w-4 h-4" />
                    New Folder
                </button>
            </div>
{/* 
            <div className="relative max-w-md">
                <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                <input
                    type="text"
                    placeholder="Search documents..."
                    value={searchQuery}
                    onChange={(e) => onSearchChange(e.target.value)}
                    className="pl-9 pr-3 py-2 w-full border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
            </div> */}
        </div>
    );
};

export default Toolbar;