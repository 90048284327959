export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
});

export const currencyFormatterTwoDecimal = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

export const dateFormatter = new Intl.DateTimeFormat("en-US");

export function dateFormaterWithTime(date) {
  // Use local time methods instead of UTC methods
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  const strTime = hours + ":" + minutes + " " + ampm;

  const month = date.getMonth() + 1;  // getMonth() returns 0-based index
  const day = date.getDate();
  const year = date.getFullYear();

  return `${month}/${day}/${year} ${strTime}`;
}


export const toMinutes = (time) => {
  return time / (60 * 1000);
};
export const toHours = (time) => {
  return time / (1000 * 60 * 60);
};
export const toDays = (time) => {
  return time / (1000 * 60 * 60 * 24);
};
export const toWeeks = (time) => {
  return time / (1000 * 60 * 60 * 24 * 7);
};
export const toMonths = (time) => {
  return time / (1000 * 60 * 60 * 24 * 7) / 4;
};
export const toYears = (time) => {
  return time / (1000 * 60 * 60 * 24) / 365;
};

export function timeFromDate(date) {
  const now = new Date()
  const startOfDate = new Date(date)
  const difference = now - startOfDate;
  const minutes = Math.floor(toMinutes(difference));
  const hours = Math.floor(toHours(difference));
  const days = Math.floor(toDays(difference));
  const weeks = Math.floor(toWeeks(difference));
  const months = Math.floor(toMonths(difference));
  const years = Math.floor(toYears(difference));
  if (difference < 0) return "future";
  if (minutes < 60) return `${minutes} minutes ago`;
  if (hours < 24) return `${hours} hours ago`;
  if (days === 0) return `today`;
  if (days === 1) return `yesterday`;
  if (days < 7) return `${days} days ago`;
  if (weeks === 1) return `${weeks} week ago`;
  if (weeks < 4) return `${weeks} weeks ago `;
  if (months === 1) return `${months} month ago`;
  if (months < 12) return `${months} months ago`;
  if (years === 1) return `1 year ago`;
  if (years > 1.5 && years < 2) return `1.5 years ago`;
  return `${years} years ago`;
}

export function timeToDate(date) {
  const now = new Date().setUTCHours(0, 0, 0, 0);
  const startOfDate = new Date(date).setUTCHours(0, 0, 0, 0);
  const difference = startOfDate - now;
  const minutes = Math.floor(toMinutes(difference));
  const hours = Math.floor(toHours(difference));
  const days = Math.floor(toDays(difference));
  const weeks = Math.floor(toWeeks(difference));
  const months = Math.floor(toMonths(difference));
  const years = Math.floor(toYears(difference));
  if (difference < 0) return "Past Due";
  if (days === 0) return `Today`;
  if (days === 1) return `Tomorrow`;
  if (days < 7) return `${days} days`;
  if (weeks === 1) return `${weeks} week`;
  if (weeks < 4) return `${weeks} weeks`;
  if (months === 1) return `${months} month`;
  if (months < 12) return `${months} months`;
  if (years === 1) return `1 year`;
  return `${years} years`;
}
export function dateFormatterGmailTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutes + " " + ampm;

  //difference
  const difference =
    new Date().setUTCHours(0, 0, 0, 0) - new Date(date).setUTCHours(0, 0, 0, 0);
  const month = date.toLocaleString("default", { month: "short" });

  if (toDays(difference) > 0) {
    return `${month} ${date.getDate()}`;
  }
  return strTime;
}


// utils/formatters.js

/**
 * Formats bytes into human readable file size
 * @param {number} bytes - Number of bytes
 * @param {number} [decimals=2] - Number of decimal places
 * @returns {string} Formatted file size
 */
export const formatBytes = (bytes, decimals = 2) => {
  if (!bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

/**
 * Formats a date string or timestamp into localized format
 * @param {string|number|Date} date - Date to format
 * @returns {string} Formatted date
 */
export const formatDate = (date) => {
  if (!date) return '';

  return new Date(date).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

/**
 * Formats file name for display (truncates if too long)
 * @param {string} fileName - File name to format
 * @param {number} [maxLength=30] - Maximum length before truncating
 * @returns {string} Formatted file name
 */
export const formatFileName = (fileName, maxLength = 30) => {
  if (!fileName) return '';

  if (fileName.length <= maxLength) return fileName;

  const extension = fileName.split('.').pop();
  const nameWithoutExt = fileName.slice(0, -(extension.length + 1));

  const truncatedName = nameWithoutExt.slice(0, maxLength - 3 - extension.length);
  return `${truncatedName}...${extension}`;
};

/**
 * Gets appropriate file type icon name based on mime type or extension
 * @param {string} mimeType - File's mime type
 * @param {string} [fileName] - Optional file name for extension check
 * @returns {string} Icon name to use
 */
export const getFileTypeIcon = (mimeType, fileName = '') => {
  const extension = fileName.split('.').pop().toLowerCase();

  // Common document types
  if (mimeType.includes('pdf') || extension === 'pdf') return 'pdf';
  if (mimeType.includes('word') || ['doc', 'docx'].includes(extension)) return 'word';
  if (mimeType.includes('sheet') || ['xls', 'xlsx'].includes(extension)) return 'excel';

  // Image types
  if (mimeType.includes('image/')) return 'image';

  // Default
  return 'document';
};
export function formattersReadbaleFileSize(fileSizeInBytes) {
  var i = -1;
  var byteUnits = [" kB", " MB", " GB", " TB", "PB", "EB", "ZB", "YB"];
  do {
    fileSizeInBytes /= 1024;
    i++;
  } while (fileSizeInBytes > 1024);

  return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
}

export const numberFormatter = new Intl.NumberFormat("en-US");
export const capitalizeFirstLetter = (string) => {
  return String(string).charAt(0).toUpperCase() + String(string).slice(1);
};

export const underscoreToSpace = (string) => {
  return String(string).replace(/_/g, " ");
};

export const formatToURL = (string = "") => {
  return string
    .toLowerCase() // convert to lowercase
    .replace(/[^\w ]+/g, "") // remove non-alphanumeric characters
    .replace(/\?/g, "") // remove question mark
    .replace(/ +/g, "-"); // replace spaces with hyphens
};

export const dateFormatterYYYYMMDD = (date = "") => {
  return new Date(date).toISOString().split("T")[0];
};


