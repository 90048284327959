import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';
import FileItem from './FileItem';
import FolderItem from './FolderItem';
import { formatBytes } from '../../../../helpers/formatters';
import { FiFile } from 'react-icons/fi';

const FileList = ({
    folders,
    documents,
    onDeleteFolder,
    onDeleteFile,
    onNavigate,
    onMove,
    documentsApi,
    authType,
    foldersApi
}) => {
    const [sortField, setSortField] = useState('name');
    const [sortDirection, setSortDirection] = useState('asc');

    const [{ isOver }, drop] = useDrop(() => ({
        accept: ['FILE', 'FOLDER'],
        drop: (item) => onMove(item, null),
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    }));

    const handleSort = (field) => {
        if (sortField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    const SortIcon = ({ field }) => {
        if (sortField !== field) return null;
        return sortDirection === 'asc' ?
            <ChevronUpIcon className="w-4 h-4" /> :
            <ChevronDownIcon className="w-4 h-4" />;
    };

    // Enhanced sort function that handles optimistic items
    const sortItems = (items, isFolder = false) => {
        return [...items].sort((a, b) => {
            // Optimistic items always go first
            if (a.isOptimistic && !b.isOptimistic) return -1;
            if (!a.isOptimistic && b.isOptimistic) return 1;

            if (sortField === 'name') {
                const aName = isFolder ? (a?.name || '') : (a?.original_name || '');
                const bName = isFolder ? (b?.name || '') : (b?.original_name || '');
                return sortDirection === 'asc' ?
                    aName.localeCompare(bName) :
                    bName.localeCompare(aName);
            }
            if (sortField === 'uploaded_at') {
                // Optimistic items use current timestamp
                const aDate = a.isOptimistic ? new Date() : new Date(a.created_at);
                const bDate = b.isOptimistic ? new Date() : new Date(b.created_at);
                return sortDirection === 'asc' ?
                    aDate - bDate :
                    bDate - aDate;
            }
            if (sortField === 'size' && !isFolder) {
                const aSize = a.isOptimistic ? a.size : a.file_size;
                const bSize = b.isOptimistic ? b.size : b.file_size;
                return sortDirection === 'asc' ?
                    aSize - bSize :
                    bSize - aSize;
            }
            return 0;
        });
    };

    const sortedFolders = sortItems(folders, true);
    const sortedDocuments = sortItems(documents);

    // Updated renderOptimisticFile function
    const renderOptimisticFile = (file) => (
        <div className="grid grid-cols-12 items-center px-4 py-2 bg-white">
            <div className="col-span-1">
                <input
                    type="checkbox"
                    className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    disabled={true}
                />
            </div>
            <div className="col-span-6">
                <div className="flex items-center gap-3">
                    <div className="p-2 bg-gray-50 rounded-lg">
                        <div className="h-5 w-5 text-gray-500 animate-pulse">
                            <FiFile className="h-5 w-5" />
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="text-sm font-medium text-gray-700">{file.name}</div>
                        <div className="mt-1 w-full bg-gray-200 rounded-full h-1.5">
                            <div
                                className="bg-blue-600 h-1.5 rounded-full transition-all duration-500"
                                style={{ width: `${file.uploadProgress || 0}%` }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-3 text-sm text-gray-500">
                Uploading...
            </div>
            <div className="col-span-2 text-sm text-gray-500">
                {formatBytes(file.size)}
            </div>
        </div>
    );
    const renderOptimisticFolder = (folder) => (
        <div className="animate-pulse flex items-center gap-2">
            <div className="h-5 w-5 bg-gray-200 rounded" />
            <div className="h-4 w-32 bg-gray-200 rounded" />
        </div>
    );



    return (
        <div
            ref={drop}
            className={`mt-4 rounded-lg ${isOver ? 'bg-blue-50' : ''}`}
        >
            {/* Header */}
            <div className="bg-gray-100 rounded-t-lg border border-gray-200">
                <div className="grid grid-cols-12 px-4 py-3">
                    <div className="col-span-1">
                      
                    </div>
                    <button
                        onClick={() => handleSort('name')}
                        className="col-span-6 flex items-center gap-1 text-gray-600 hover:text-gray-900"
                    >
                        <span className="font-medium">File Name</span>
                        <SortIcon field="name" />
                    </button>
                    <button
                        onClick={() => handleSort('uploaded_at')}
                        className="col-span-3 flex items-center gap-1 text-gray-600 hover:text-gray-900"
                    >
                        <span className="font-medium">Upload Date</span>
                        <SortIcon field="uploaded_at" />
                    </button>
                    <button
                        onClick={() => handleSort('size')}
                        className="col-span-2 flex items-center gap-1 text-gray-600 hover:text-gray-900"
                    >
                        <span className="font-medium">Size</span>
                        <SortIcon field="size" />
                    </button>
                </div>
            </div>

            {/* List Items */}
            <div className="divide-y divide-gray-200 border-x border-b border-gray-200 rounded-b-lg bg-white">
                {sortedFolders.map((folder) => (
                    <div key={folder.id} className="grid grid-cols-12 items-center px-2 py-2">

                        <div className="col-span-12">
                            {folder.isOptimistic ? (
                                renderOptimisticFolder(folder)
                            ) : (
                                <FolderItem
                                    folder={folder}
                                    onDelete={onDeleteFolder}
                                    onNavigate={onNavigate}
                                    onDrop={onMove}
                                    foldersApi={foldersApi}
                                />
                            )}
                        </div>
                    </div>
                ))}

                {sortedDocuments.map((file) => (
                    <React.Fragment key={file.id}>
                        {file.isOptimistic ? (
                            renderOptimisticFile(file)
                        ) : (
                            <FileItem
                                file={file}
                                onDelete={onDeleteFile}
                                documentsApi={documentsApi}
                                authType={authType}
                            />
                        )}
                    </React.Fragment>
                ))}

                {!sortedFolders.length && !sortedDocuments.length && (
                    <div className="px-4 py-8 text-center text-gray-500">
                        No files or folders found
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileList;