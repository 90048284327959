import React from 'react';
import BreadcrumbItem from './BreadcrumbItem';

const Breadcrumbs = ({
    breadcrumb = [],
    onNavigate,
    onDrop
}) => {
    const isRootFolder = breadcrumb.length === 0;

    // Add home as the first item
    const allItems = [
        { id: 'home', name: 'Home', isHome: true },
        ...breadcrumb
    ];

    return (
        <nav
            aria-label="Breadcrumb"
            className="flex w-full pt-4 px-4"
        >
            <ol
                className="flex items-center flex-wrap gap-y-2 w-full"
            >
                {allItems.map((item, index) => (
                    <BreadcrumbItem
                        key={item.id}
                        item={item}
                        isLast={index === allItems.length - 1}
                        onNavigate={onNavigate}
                        onDrop={onDrop}
                        isRootFolder={isRootFolder}
                    />
                ))}
            </ol>
        </nav>
    );
};

export default Breadcrumbs;